.card-type-header {
  background: var(--text-color);
  color: var(--white-color);
  border-radius: 5px;
  margin-bottom: 10px;
}

.view-subscription-heading h1 {
  font-size: 22px;
  color: var(--black-text-color);
  font-weight: 600;
}
.view-subscription-edit {
  font-size: 16px;
  padding: 8px 20px;
  color: var(--gradient-start-color);
  background-color: var(--white-color);
  border: 1px solid var(--gradient-start-color);
  border-radius: 5px;
}
.view-subscription-create {
  font-size: 16px;
  padding: 8px 20px;
  color: var(--white-color);
  background-color: var(--gradient-start-color);
  border-radius: 5px;
}

.card-subscription-plan {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 32px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: relative;
  background-image: url("../../Assets/Images/subscriptionplan.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background: #ffffff;
}

.pricing-plan-header h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: var(--black-text-color);
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 8px;
}

.vertical-line-plans {
  border-left: 2px solid var(--gradient-start-color);
  height: 40px;
  margin: 0 10px;
}
.pricing-plan-header p {
  font-size: 14px;
  color: var(--black-text-color);
  font-weight: 700;
  text-align: left;
  margin-bottom: 0;
}
.price-plan-title {
  font-size: 20px;
  color: var(--black-text-color);
  font-weight: 600;
  margin: 10px 10px;
}
.horizontal-line-plans {
  border: 1px solid var(--gradient-start-color);
  width: 30px;
  margin: auto;
}
.price-plan-content-head {
  font-size: 16px;
  color: var(--text-secondary-color);
  margin-bottom: 16px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.plans-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.icon-circle {
  background-color: var(--green-bg-color);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.check-icon {
  color: var(--green-color);
  width: 12px;
  height: 10.5px;
  font-weight: 600;
}

.feature-text {
  font-size: 16px;
  color: var(--text-secondary-color);
  text-align: left;
}

.edit-subscription-btn:hover {
  background-color: var(--gradient-start-color);
  color: var(--white-color);
  border: none;
  padding: 4px 10px;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}
.edit-subscription-btn,
.view-subscription-create:hover {
  background-color: var(--white-color);
  color: var(--gradient-start-color);
  border: 1px solid var(--gradient-start-color);
  padding: 4px 10px;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}

.card-subscription-plan:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .pricing-plan-header h2 {
    font-size: 24px;
  }

  .card-subscription-plan {
    margin-bottom: 20px;
  }
}

.manage-payments-section h2 {
  font-size: 22px;
  color: var(--black-text-color);
  font-weight: 600;
}
.payer-information-section {
  padding: 32px;
}
.payer-information-section h4 {
  color: var(--background-color);
  font-size: 22px;
  font-weight: 900;
}
.payer-icon-section {
  text-align: center;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 30px;
  border: 1px solid var(--border-color);
  padding: 32px;
  border-radius: 8px;
}
.payer-icon-section h2 {
  font-size: 22px;
  color: var(--black-text-color);
  font-weight: 900;
}
.payer-icon-section h1 {
  font-size: 18px;
  color: var(--black-text-color);
}
.payer-icon-section p {
  font-size: 18px;
  color: var(--black-text-color);
  font-weight: 600;
  margin-bottom: 0;
}
.payer-icon-section span {
  font-size: 18px;
  color: var(--text-secondary-color);
  font-weight: 600;
  margin-bottom: 0;
}
.view-payer-form.ant-form-item .ant-form-item-label > label {
  font-size: 14px;
}

.billing-delete-modal .ant-modal-content {
  padding: 40px;
}

.billing-header h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.billing-header h6 {
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;
  color: var(--text-secondary-color);
  margin-bottom: 0;
}

.billing-contact-info {
  margin: 0;
  align-items: end;
}

.billing-contact-info h1 {
  font-size: 12px;
  color: var(--black-text-color);
}
.billing-contact-info h3 {
  font-size: 12px;
  color: var(--secondary-medium-blue);
  text-decoration: underline;
}
.billing-contact-info h4 {
  font-size: 12px;
  color: var(--black-text-color);
}
.billing-contact-info h5 {
  font-size: 12px;
  color: var(--black-text-color);
}
.billing-contact-info span {
  font-size: 12px;
  color: var(--text-secondary-color);
}

.customer-info p {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
  font-size: 12px;
}

.label {
  width: 130px;
  font-weight: 600;
  color: var(--text-secondary-color);
}

.separator {
  margin: 0 10px;
  font-weight: bold;
}

.value {
  font-weight: 600;
  color: var(--black-text-color);
  width: 200px;
}

.billing-table {
  margin-top: 20px;
  border-top: 2px solid var(--border-color);
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 20px;
   display: grid;
    gap: 10px;
    margin-top: 20px;
  
}

.table-header, .table-row {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
}

.table-header {
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

.table-row {
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}

.table-header p {
  font-weight: bold;
  font-size: 14px;
}

.table-row-gst {
  font-size: 14px;
}
.table-row.total-row {
  font-weight: 600;
  font-size: 16px;
  border-bottom: none;
}

.billing-footer {
  margin-top: 20px;
  font-size: 12px;
}

.signature p {
  margin: 5px 0;
  font-weight: 600;
}

.signature p:first-child {
  font-weight: bold;
  font-size: 12px;
}
.billing-authorization {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--border-color);
}
.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.search-table-container .create-invoice,
.create-invoice {
  color: var(--white-color);
  border: 1px solid var(--gradient-start-color);
  background-color: var(--gradient-start-color);
  font-size: 12px;
  padding: 8px 16px;
  letter-spacing: 1px;
}
.search-table-container .create-invoice:hover,
.create-invoice:hover {
  background-color: var(--white-color);
  color: var(--gradient-start-color);
  border: 1px solid var(--gradient-start-color);
}
.ivoice-form-heading {
  font-size: 20px;
  color: var(--gradient-start-color);
  font-weight: 600;
}
.view-invoice-page {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 15px;
}
.payment-gateway-notification {
  font-size: 12px;
  border: 1px solid var(--border-color);
  background-color: var(--bglight-blue-color);
  border-radius: 5px;
  padding: 10px;
}
.gateway-notification-button {
  background-color: none;
  border: 1px solid var(--border-color);
}
.notification-alert {
  color: var(--gradient-end-color);
  font-size: 18px;
  font-weight: 600;
}
.request-span span {
  width: 12px;
  height: 12px;
  background-color: var(--green-button-color);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
.request-span {
  color: var(--black-text-color);
  font-weight: 600;
  font-size: 14px;
}
.request-time {
  color: var(--footer-secondary-color);
  font-weight: 400;
  font-size: 12px;
}
.request-heading {
  font-size: 18px;
  font-weight: 600;
  color: var(--black-text-color);
}
.request-paragraph {
  color: var(--footer-secondary-color);
  font-weight: 500;
}
.button-reject {
  background: none;
  color: var(--maroon-color);
}
.button-accept {
  background: none;
  color: var(--green-button-color);
}
@media (max-width: 768px) {
  .search-table-container .create-invoice,
  .create-invoice {
    padding: 8px 8px;
  }
}
.custom-modal-title {
  color: var(--gradient-start-color);
  text-decoration: underline;
  text-underline-offset: 4px;
  font-size: 16px;
}
.add-all-users-button {
  color: var(--white-color);
  border: 1px solid var(--gradient-start-color);
  background-color: var(--gradient-start-color);
  font-size: 12px;
  padding: 8px 16px;
  letter-spacing: 1px;
}

.add-all-users-button:hover{
  background-color: var(--white-color);
  color: var(--gradient-start-color);
  border: 1px solid var(--gradient-start-color);
}

.create-subscription-cancel-button{
  background-color: var(--white-color);
  color: var(--gradient-start-color);
  border: 1px solid var(--gradient-start-color);
  margin-right: 10px;
}

.create-subscription-cancel-button:hover{
  color: var(--white-color) !important;
  border: 1px solid var(--gradient-start-color) !important;
  background-color: var(--gradient-start-color) !important;
}

.subscription-type-tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--gradient-start-color);
  color: var(--white-color);
  padding: 10px 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0;
  font-size: 12px;
  font-weight: 800;
  opacity: 0.7;
}
.edit-subscription-btn.btn-active{
  color: var(--green-color);
  border: 1px solid var(--green-color);
  cursor: pointer;
  }
.edit-subscription-btn.btn-active:hover {
  background-color: var(--green-color); 
  color: var(--white-color);
  cursor: pointer; 
  opacity: 0.6;
}
.edit-subscription-btn.btn-inactive {
  color: var(--danger-color);
  border: 1px solid var(--danger-color);
}
.edit-subscription-btn.btn-inactive:hover {
  background-color: var(--danger-color);
  color: var(--white-color);
  cursor: pointer;
}

.card-disabled {
  background-color: #e0dbdb; 
  border: 1px solid #a694f5;
  opacity: 0.6; 
 
}
.invoice-vertical-line{
  border: 1px solid var(--border-color);

}