.pricing-banner-section {
  padding: 100px 0;
  background-image: url("../../Assets/image/home/features-banner.svg");
  background-position: center;
  height: 200px;
  background-size: cover;
}

.pricing-banner h2 {
  font-size: 30px;
  margin-bottom: 15px;
  font-weight: 800;
  color: var(--text-color);
  text-align: center;
}

.pricing-banner p {
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 500;
  color: var(--text-secondary-color);
  text-align: center;
}

.pricing-banner-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.pricing-apple-btn {
  background: linear-gradient(
    90deg,
    var(--gradient-start-color),
    var(--gradient-end-color)
  );
  border-color: var(--primary-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 8px;
  margin-right: 12px;
  font-size: 14px;
  border: 0;
}

.pricing-apple-btn:hover {
  background: linear-gradient(
    90deg,
    var(--gradient-end-color),
    var(--gradient-start-color)
  );
  border-color: var(--gradient-start-color);
}

.pricing-android-btn {
  color: var(--text-color);
  position: relative;
  font-weight: 600;
  border: 1px solid var(--text-color);
  border-radius: 8px;
  padding: 8px 12px;
  background: transparent;
  font-size: 14px;
}

.pricing-android-btn:hover {
  background: linear-gradient(
    90deg,
    var(--gradient-start-color),
    var(--gradient-end-color)
  );
  border-color: var(--gradient-start-color);
  color: var(--white-color);
}

/* pricing plan css */

.pricing-plan-section {
  padding: 40px 0;
  background: linear-gradient(90deg, #D7E8FF 0%, #83b7ff 50%);
}

.pricing-plan-section h1 {
  text-align: center;
  color: var(--white-color);
  margin-bottom: 20px;
  font-size: 35px;
  font-weight: 900;
  letter-spacing: -0.1px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.price-button-group .price-explore-button,
.price-button-group .price-learn-more-btn {
  background-color: var(--white-color);
  color: var(--store-background);
  padding: 5px 20px;
  border-radius: 10px;
}

.price-button-group .price-explore-button.active,
.price-button-group .price-learn-more-btn.active {
  color: var(--white-color);
  background-color: var(--gradient-end-color);
  border: 0;
}

.price-button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 70px;
}
.billing-cycle-radio-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.white-text-radio-group .ant-radio-wrapper {
  color: var(--white-color);
}

.pricing-card {
  background-color: var(--secondary-white-color);
  border-radius: 20px;
  padding: 30px;
  width: 320px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.01);
  transition: transform 0.3s ease;
  position: relative;
}

.pricing-card:hover {
  transform: translateY(-10px);
}

.pricing-card h2 {
  font-size: 24px;
  color: var(--text-color);
  margin-top: 40px;
  font-weight: 800;
  letter-spacing: -0.1px;
}

.price {
  font-size: 54px;
  font-weight: 900;
  color: var(--text-color);
  letter-spacing: -0.1px;
}

.per-month {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
}

.pricing-card h3 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
  letter-spacing: -0.1px;
  color: var(--text-color);
}

.pricing-card ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 50px;
  text-align: center;
}

.pricing-card li {
  margin-bottom: 10px;
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
}

.pricing-card button {
  width: 94%;
  padding: 10px;
  background: linear-gradient(
    90deg,
    var(--gradient-start-color),
    var(--gradient-end-color)
  );
  color: var(--white-color);
  border: none;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.pricing-card button:hover {
  background: linear-gradient(
    90deg,
    var(--gradient-start-color),
    var(--gradient-end-color)
  );
}

.popular {
  background: linear-gradient(
    90deg,
    var(--gradient-start-color),
    var(--gradient-end-color)
  );
  color: var(--white-color);
  position: relative;
}

.popular h2,
.popular .price,
.popular .per-month,
.popular h3 {
  color: var(--white-color);
}

.popular li {
  color: #e6e6e6;
}

.popular button {
  background: var(--white-color);
  color: var(--gradient-end-color);
}

.popular button:hover {
  background: var(--semi-white-color);
}

.popular-tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--icon-hover-color);
  color: var(--white-color);
  padding: 10px 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0;
  font-size: 12px;
  font-weight: 800;
}

@media (max-width: 1000px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }
}

/* Contact form css */

.contact-form-section {
  padding: 80px 0;
  background: var(--template-background-color);
}

.contact-form-section h1 {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -0.1px;
}

.contact-form-section p {
  text-align: center;
  color: var(--text-secondary-color);
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.1px;
}

/* form {
  padding: 50px;
  background: var(--white-color);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);
} */

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
}

input,
textarea {
  padding: 10px;
  border-bottom: 2px solid var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

input.error,
textarea.error {
  border-bottom: 2px solid var(--danger-color);
}

input:focus.error,
textarea:focus.error {
  border-bottom: 2px solid var(--danger-color);
}

input:focus,
textarea:focus {
  outline: none;
  border-bottom: 2px solid var(--gradient-end-color);
}

textarea {
  height: 100px;
  resize: none;
}

.submit-btn {
  background: linear-gradient(
    90deg,
    var(--gradient-start-color),
    var(--gradient-end-color)
  );
  color: var(--white-color);
  border: none;
  padding: 12px 48px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 40px;
}

.error {
  color: var(--danger-color);
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
  letter-spacing: -0.1px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }

  form {
    padding: 20px !important;
  }

  .form-group {
    margin-top: 12px;
  }

  .popular h2,
  .popular .price,
  .popular .per-month,
  .popular h3 {
    text-align: left !important;
  }

  .pricing-card h2,
  .pricing-card .price,
  .pricing-card .per-month,
  .pricing-card h3 {
    text-align: left !important;
  }

  .pricing-banner h2,
  .pricing-plan-section h1 {
    font-size: 24px !important;
  }
}

/* Price benefits css */
.pricing-benefits-section {
  padding: 40px 0;
  color: var(--black-text-color);
}
.pricing-benefits-images{
  padding: 20px;
}
.benefits-list {
  list-style: none;
  padding-left: 0;
}
.price-line{
  color: var(--white-color);
}
.benefits-list ul {
  list-style: none;
}
.benefits-list li {
  color: var(--text-color);
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 5px
}
.price-benefits-bg{
  background: linear-gradient(to bottom right, #D7E8FF 0%, #9AC5FF 80%, #2A84FF 100%);
  padding: 20px;
  border-radius: 5px;
}

/* Pricing Feature Action */

.pricing-diskuss-action {
  padding: 40px 0;
  background: linear-gradient(to bottom, #fff, #468EF0 50%, #fff);
}

.feature-content-action {
  margin-top: 60px;
  position: relative;
}

.feature-description {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 100px !important;
}

.feature-description h3 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.2px;
  color: var(--text-color);
  margin-bottom: 20px;
}

.feature-description p {
  color: var(--text-color);
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
}

.feature-list {
  list-style-type: none;
  padding: 0;
}

.feature-list li {
  padding: 12px;
  background-color: var(--black-text-color);
  border-radius: 24px;
  margin: 8px 0;
  list-style-type: none;
  width: fit-content;
  font-size: 14px;
  color: var(--white-color);
  font-weight: 500;
  letter-spacing: -0.1px;
  border: 1px solid var(--white-color);
  display: flex;
  align-items: center;
  gap: 5px;
}

.action-line {
  height: 180px;
  width: 10px;
  background: var(--gradient-end-color);
  border-radius: 12px;
}

.feature-cards-action {
  height: 100%;
}

.feature-card-action {
  background-color: var(--black-text-color);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--white-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01);
}

.feature-card-action.highlighted {
  background: linear-gradient(
    270deg,
    var(--gradient-start-color),
    var(--gradient-end-color)
  );
  color: var(--white-color);
}

.feature-card-action.highlighted .feature-icon {
  background-color: var(--icon-hover-color);
  color: var(--white-color);
}

.feature-icon {
  font-size: 24px;
  margin-bottom: 10px;
  background-color: var(--icon-background-color);
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-card-action.highlighted h3 {
  color: var(--white-color);
}

.feature-card-action h3 {
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: 8px;
  font-weight: 700;
  letter-spacing: -0.2px;
  color: var(--white-color);
}

.feature-card-action p {
  font-size: 14px;
  color: var(--white-color);
  font-weight: 500;
  letter-spacing: -0.1px;
}

.feature-card-action.highlighted p {
  color: var(--semi-white-color);
}

@media (max-width: 768px) {
  .feature-content-action {
    flex-direction: column;
  }
  .pricing-benefits-section {
    padding: 10px 0;
  }
  .action-line {
    display: none;
  }
}