.business-card {
  width: 100%;
  max-width: 470px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: auto;
}

.business-card-first-container {
  /* / height: 120px; / */
  width: 100%;
  /* / background: linear-gradient(to bottom, #0177DB, #FFFFFF); / */
  background: linear-gradient(
    to bottom,
    rgba(1, 119, 219, 1),
    rgba(255, 255, 255, 1)
  );
  /* / border-bottom-left-radius: 30px; / */
  /* / border-bottom-right-radius: 30px; / */
  /* / border: 1.2px solid white; / */
  backdrop-filter: blur(20px) !important;
  /* / box-shadow: 0px 5px 10px rgba(84, 106, 126, 0.5); / */
  /* / box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; / */
  padding: 60px 0px;
}

.firstend-container {
  width: auto;
  /* / background: linear-gradient(to bottom, #0177db, #ffffff); / */
  background-color: #0177db;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px 5px 10px rgba(79, 165, 241, 0.5);
  margin-top: 20px;
}

.secondend-container {
  width: auto;
  background-color: #b1d7f8;
  color: #000;
  font-size: 15px;
  font-weight: 600;
}

.business-card-icons a {
  color: white;
  transition: color 0.3s ease-in-out;
}

.footer-icons {
  display: flex;
  align-items: center;
  padding: 12px 0px 12px 0px;
  font-size: 40px;
  font-weight: 700;
  color: white;
  justify-content: space-around;
}

.footer-icons a {
  color: white;
  transition: color 0.3s ease-in-out;
}

.save-contact {
  background-color: #0177db;
  border: none;
  border-radius: 15px;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  width: 60%;
  color: white;
  display: block;
  margin: 10px auto;
  margin-top: 20px;
}

.save-contact:hover {
  background: linear-gradient(
    90deg,
    rgb(163, 159, 231) 0%,
    rgba(144, 225, 239, 0.995) 35%,
    rgba(36, 214, 209, 1) 100%
  );
}

.business-card-first-container-headtext {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
}

.secondtext {
  padding-top: 8px;
}

.flip-container {
  perspective: 1000px;
  cursor: pointer;
  -webkit-perspective: 1000px; /* Add webkit prefix */
}

.contentone {
  position: absolute;
  transform: rotateY(180deg);
}

.flipper {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s; /* Add webkit prefix */
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d; /* Add webkit prefix */
  height: 200px;
  position: relative; /* Add this */
}

.flipped {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Add webkit prefix */
}

.front {
  position: absolute;
  backface-visibility: hidden !important;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: 100%;
  padding: 30px 10px;
  height: 190px;
  -webkit-backface-visibility: hidden; /* Add webkit prefix */
}
.business-card-container {
  padding: 10px;
  text-align: center;
}
.business-card-second-container {
  background-color: transparent;
  margin: auto;
  margin-top: -55px;
  display: flex;
  border-radius: 10px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  min-height: 190px;
  width: 430px;
}
.business-card-second-container.flipped {
  transform: rotateY(180deg);
}
.business-card-profile {
  background-color: #000000c2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.business-card-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-card-content {
  background-color: #0177db;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 20px;
}
.business-card-profile,
.business-card-content {
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden; /* Add webkit prefix */
}
.business-card-button {
  background-color: transparent;
  color: white;
  border: none;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.business-card-button p {
  margin: 2px 0;
  line-height: 1.3;
  font-style: 🇲🇸;
  font-size: 14px;
}

.business-card-icon {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 2;
}
.business-card-icon-style {
  font-size: 14px;
}
.business-card-icon a {
  font-size: 26px;
}
.text-style {
  font-size: 14px;
  width: 200px;
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.business-card-iconss a {
  color: white;
  transition: color 0.3s ease-in-out;
}

.business-card-iconss a:hover {
  color: lightgray;
}

.business-card-iconss {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 8px;
  margin-right: 50px;
}

.back {
  color: white;
  backface-visibility: hidden !important;
  transform: rotateY(180deg);
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-radius: 15px;
  height: 200px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: absolute;
  justify-content: center;
  padding: 30px 10px;
  -webkit-backface-visibility: hidden; /* Add webkit prefix */
}
.back-card-container {
  padding: 10px;
  text-align: center;
}
.back-card-second-container {
  background-color: transparent;
  margin: auto;
  margin-top: -55px;
  display: flex;
  border-radius: 10px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  width: 430px;
  min-height: 190px;
}
.back-card-second-container.flipped {
  transform: rotateY(180deg);
}
.back-card-profile {
  background-color: #000000c2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.back-card-profile{
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden; /* Add webkit prefix */
}
.back-card-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-card-content {
  background-color: #0177db;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 20px;
}
.back-card-content {
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden; /* Add webkit prefix */
}
.back-card-button {
  background-color: transparent;
  color: white;
  border: none;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.back-card-button p {
  margin: 2px 0;
  line-height: 1.3;
  font-style: 🇲🇸;
  font-size: 14px;
}

.back-card-services-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.back-card-service-column {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.back-card-service-column li {
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 2px;
  color: white;
}

.back-card-website-link {
  position: absolute;
  bottom: 5px;
  display: block;
  text-align: left;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  text-decoration: underline;
  align-items: start;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  white-space: normal;
  word-break: break-word;
  max-width: 100%;
}

.business-card-services-list2 {
  margin: 15px;
  padding: 10px 0px;
  box-shadow: rgba(24, 107, 185, 0.15) 0px 15px 25px,
    rgba(43, 107, 204, 0.05) 0px 5px 10px;
  background-color: #e6f7ff;
}
.business-card-services-list2 h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  padding: 0px 6px 10px 6px;
}

.business-card-services-list2 ul {
  list-style-type: disc;
  margin-top: 0;
  padding-left: 74px;
}

.business-card-services-list2 li {
  margin: 0 auto;
  text-align: left;
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 25px;
}

.business-card-services-list2 li::marker {
  font-size: 14px;
  color: black;
}
.business-contact-details {
  margin-top: -15px;
  padding: 0px 35px;
}

.business-contact-details h4 {
  font-weight: 600;
  font-size: 28px;
}
.business-contact-details h5 {
  font-weight: 600;
  font-size: 20px;
}
.user-phone {
  font-size: 20px;
  display: flex;
  text-align: left;
  align-items: start;
  word-wrap: break-word !important;
  overflow-wrap: break-word;
  white-space: normal;
  word-break: break-word;
  max-width: 100%;
}

.user-email {
  font-size: 20px;
  display: flex;
  text-align: left;
  align-items: start;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  white-space: normal;
  word-break: break-word;
  max-width: 100%;
}
.user-phone svg {
  flex-shrink: 0;
  margin-right: 5px;
}
.user-email svg {
  flex-shrink: 0;
  margin-right: 5px;
  margin-top: 5px;
}
@media (max-width: 768px) {
 .front{
  padding: 10px 0px;
 }
  .business-card-second-container {
    flex-direction: row;
    min-height: 205px !important;
    width: 100%;
  }
  .business-card-profile {
    width: 30%;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  .business-card-image img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .business-card-content {
    width: 70%;
    padding: 15px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }

  .business-card-button p {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .text-style {
    font-size: 14px;
    width: 100%;
    word-break: break-word;
  }
  .business-card-icon-style {
    font-size: 14px;
  }
  .business-card-iconss {
    display: flex;
    gap: 12px;
    margin-top: 8px;
  }

  .business-card-iconss a {
    font-size: 18px;
  }

  .business-card {
    width: 100%;
  }
  .footer-icons {
    gap: 40px;
    padding: 12px 0px 10px 0px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .save-contact {
    height: 45px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-size: 14px;
  }
  .back{
    padding: 10px 0px;
   }

  .back-card-second-container {
    flex-direction: row;
    width: 100%;
    min-height: 205px !important;
  }
  .back-card-profile {
    width: 30%;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
  .back-card-image img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .back-card-content {
    width: 70%;
    padding: 15px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
  .back-card-button p {
    font-size: 14px;
    margin-bottom: 4px;
  }
}
/* iOS-specific card flip improvements - CSS only solution */
@media only screen and (max-width: 768px) {
  @supports (-webkit-touch-callout: none) {
    /* Container setup */
    .flip-container {
      perspective: none;
      -webkit-perspective: none;
    }
    
    .flipper {
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      position: relative;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
    }
    
    /* Reset the transforms but keep the class toggle functionality */
    .flipped {
      transform: rotateY(0deg); 
      -webkit-transform: rotateY(0deg);
    }
    
    .front, .back {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      backface-visibility: visible;
      -webkit-backface-visibility: visible;
      transform: rotateY(0deg);
      -webkit-transform: rotateY(0deg);
    }
    
    /* Default state */
    .front {
      opacity: 1;
      transform: scale(1);
      -webkit-transform: scale(1);
      z-index: 2;
    }
    
    .back {
      opacity: 0;
      transform: scale(0.8) rotateY(180deg);
      -webkit-transform: scale(0.8) rotateY(180deg);
      z-index: 1;
    }
    
    /* Flipped state with simulated rotation */
    .flipped .front {
      opacity: 0;
      transform: scale(0.8) rotateY(-180deg);
      -webkit-transform: scale(0.8) rotateY(-180deg);
      z-index: 1;
    }
    
    .flipped .back {
      opacity: 1;
      transform: scale(1) rotateY(0deg);
      -webkit-transform: scale(1) rotateY(0deg);
      z-index: 2;
    }
    
    /* Add transition effects to better simulate rotation */
    .front, .back {
      transition-property: transform, opacity;
      transition-duration: 0.6s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    
    /* Flash effect during transition */
    .flip-container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0;
      z-index: 10;
      pointer-events: none;
      transition: opacity 0.3s;
    }
    
    .flip-container:active::after {
      opacity: 0.2;
    }
    
    /* Performance improvements */
    .flip-container, .flipper, .front, .back {
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      transform: translateZ(0);
      backface-visibility: hidden;
    }
  }
}

.google-playstores,
.apple-stores {
  display: flex;
  align-items: center;
  justify-content: start;
  background: var(--gradient-start-color);
  border-radius: 12px;
  padding: 8px 12px;
  margin: 6px 0;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.google-playstores:hover,
.apple-stores:hover {
  background: var(--gradient-end-color);
  transform: translateY(-4px); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.google-playstores span,
.apple-stores span {
  font-size: 12px;
  color: var(--semi-white-color);
  font-weight: 400;
  letter-spacing: 0.2px;
}

.google-playstores h4,
.apple-stores h4 {
  font-size: 16px;
  color: var(--white-color);
  font-weight: 600;
  letter-spacing: 0.2px;
}
.available-storess h2{
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: var(--text-color);
}
.icon-stores {
  margin-right: 12px;
  font-size: 20px;
  color: var(--white-color);
}
.content-stores{
  text-align: left;
}

