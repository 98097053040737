*{
  padding: 0;
  margin: 0;
}

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1))
}

/* Normal button design */
.back-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;  /* Button background color */
  color: white;  /* Button text color */
  border-radius: 5px;  /* Rounded corners */
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

/* Hover effect */
.back-btn:hover {
  background-color: var(--white-color);  
color: var(--gradient-end-color);
border: 1px solid var(--gradient-end-color);
}

/* Focus effect removal */
.no-focus:focus {
  outline: none;
  box-shadow: none;
}
.employee-details-modal p{
  font-size: 16px;
}