.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: var(--black-text-color);
}

.login-left {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.login-right {
  padding-left: 0;
  background-image:url("../Assets/Images/Cover.png") ;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

/* .login-right img {
  height: 100vh;
  width: 100%;
} */

.login-card {
  background-color: var(--white-color);
  border-radius: 12px;
  padding: 30px;
  position: relative;
  /* left: 80px; */
  align-items: center;
}

.login-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--black-text-color);
  letter-spacing: -0.2px;
}

.login-card p {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  letter-spacing: -0.2px;
}

.forgot-password-button {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--gradient-start-color);
}

.sign-button {
  width: 100%;
  background-color: var(--gradient-start-color);
  color: var(--white-color);
  border-radius: 6px;
  padding: 8px 16px;
  margin-top: 4px;
  font-size: 14px;
}
.sign-button:hover{
  background-color: var(--white-color);
  color: var(--gradient-start-color);
  border: 1px solid var(--gradient-start-color);
}
.google-button img {
  width: 24px;
  height: 24px;
}

.google-button {
  background-color: transparent;
  border: 1px solid var(--secondary-gray-color);
  font-weight: 600;
  width: 100%;
  font-size: 14px;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: center;
}

.login-form p {
  color: var(--secondary-gray-color);
  font-size: 14px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0;
}
.login-heading {
  background-color: transparent;
  color: var(--white-color);
  font-size: 40px;
  font-weight: 900px;
  position: relative;
  /* left: -60px; */
}

.otp-input-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.otp-input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  border: 1px solid var(--footer-secondary-color);
  border-radius: 10px;
  margin: 0 8px;
  color: var(--black-text-color);
  background-color: transparent;
  caret-color: transparent;
}

.otp-input:focus {
  outline: none;
  border-color: var(--gradient-start-color);
  caret-color: var(--white-color);
}

.resend-info {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 6px;
}

.resend-info p {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
}

.resend-info span {
  color: var(--secondary-gray-color);
  font-size: 14px;
  font-weight: 600;
}

.resend-link {
  color: var(--danger-color) !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}
.resend-link:hover {
  color: var(--gradient-start-color) !important;
}

.ant-input-outlined {
  padding: 8px 16px;
}

.ant-form-item {
  margin-bottom: 12px;
}
.send-otp-button{
  background-color: var(--white-color);
  color: var(--gradient-start-color);
  border: 1px solid var(--gradient-start-color);
  padding: 2px;
}
.send-otp-button:hover{
  background-color: var(--gradient-start-color);
  color: var(--white-color);
}
@media (max-width: 768px) {
  .login-container {
    background-color: var(--black-text-color);
  }

  .login-right {
    display: none;
  }

  .login-card {
    left: 0;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .login-container {
    background-color: var(--black-text-color);
  }

  .login-right {
    display: none;
  }

  .login-card {
    left: 0;
  }
}
.register-btn {
  color: var(--gradient-start-color);
  margin-left: 5px;
  font-weight: 600;
}
.register-btn:hover {
  color: var(--secondary-gray-color);
}
.login-card-forgot {
  background-color: var(--white-color);
  border-radius: 12px;
  padding: 30px;
  position: relative;
  left: 40px;
  align-items: center;
}
.login-card-forgot h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--black-text-color);
  letter-spacing: -0.2px;
}

.login-card-forgot p {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  letter-spacing: -0.2px;
}
.login-heading-forgot {
  background-color: transparent;
  color: var(--white-color);
  font-size: 40px;
  position: relative;
  /* left: -140px; */
  margin-bottom: 20px;
}