.allTickets-ticket-title{
    font-weight: 700;
    color: black;
}
.allTickets-p{
    color: black;
}
.allTicket-details-div{
    border: 1px solid #e7e7e7 !important;
    border-radius: 6px;
    padding: 10px;
}
.allTickets-low-priority-p{
    color: yellow;
    font-size: 11px;
    background-color: rgb(138, 188, 0);
    border-radius: 5px;
    padding: 3px 6px;
}
.allTickets-medium-priority-p{
    color: white;
    font-size: 11px;
    background-color: orange;
    border-radius: 5px;
    padding: 3px 6px;
}
.allTickets-high-priority-p{
    color: white;
    font-size: 11px;
    background-color: red;
    border-radius: 5px;
    padding: 3px 6px;
}
.allTickets-openticket-button{
    background:none;
    text-decoration: underline;
    border: none;
    color: rgb(104, 156, 235);
}
.allTickets-closedticket-button{
    background:none;
    text-decoration: none;
    border: none;
    color: rgb(21, 171, 13);
}
.openTicket-reply-to{
    padding: 15px;
    background-color: #f8f8f8 !important;
    border: 1px solid #eeeeee;
    border-radius: 6px;
}
.view-ticket-circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}
.supportticket-viewTicket-filters-buttons{
    background: none;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 600;
}
