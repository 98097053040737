/* BroadcastMessage.css */
.broadcast-container {
  max-width: 100%;
  padding: 24px;
  background-color: #f9fafc;
}

.broadcast-header {
  margin-bottom: 24px;
  border-bottom: 1px solid #e1e5eb;
  padding-bottom: 16px;
}

.broadcast-header h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 8px;
}

.broadcast-header p {
  color: #666;
  font-size: 14px;
  margin: 0;
}

.broadcast-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin-bottom: 32px;
}

.broadcast-card h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 16px;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
}

.form-group {
  margin-bottom: 16px;
}

.form-row {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.form-row .form-group {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #444;
  font-size: 14px;
}

input, select, textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

/* Enhanced file input styling */
.file-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
}

.custom-file-input {
  display: inline-block;
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.custom-file-input:hover {
  background-color: #3a7bc8;
}

.file-name {
  margin-left: 10px;
  font-size: 14px;
  color: #666;
}

/* Image preview styling */
.image-preview-container {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview {
  max-width: 200px;
  max-height: 150px;
  display: block;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.remove-image:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.form-actions {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.btn-primary, .btn-secondary {
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #4a90e2;
  color: white;
}

.btn-primary:hover:not(:disabled) {
  background-color: #3a7bc8;
}

.btn-primary:disabled {
  background-color: #a5c2e7;
  cursor: not-allowed;
}

.btn-secondary {
  background-color: #f5f5f5;
  color: #666;
  border: 1px solid #ddd;
}

.btn-secondary:hover {
  background-color: #ebebeb;
}

/* Broadcast History Styles */
.broadcast-history {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  padding: 24px;
}

.broadcast-history h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 16px;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.no-history {
  padding: 24px;
  text-align: center;
  color: #666;
  background-color: #f9fafc;
  border-radius: 6px;
  border: 1px dashed #ddd;
}

.history-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 16px;
  background-color: #fcfcfc;
  transition: all 0.2s ease;
}

.history-item:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.history-content {
  flex: 1;
}

.history-message {
  font-size: 15px;
  color: #333;
  margin: 0 0 8px;
}

.history-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  font-size: 12px;
  color: #777;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.status-delivered {
  background-color: #fff8e1;
  color: #ff8f00;
}

.status-read {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.message-image {
  margin-left: 16px;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #eee;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 8px;
  }
  
  .form-actions {
    flex-direction: column;
  }
  
  .btn-primary, .btn-secondary {
    width: 100%;
  }
  
  .history-item {
    flex-direction: column;
  }
  
  .message-image {
    margin-left: 0;
    margin-top: 12px;
  }
}