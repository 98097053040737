.referral-top-winners-heading {
  font-size: 20px;
  font-weight: 600;
}
.referral-numbers {
  background-color: var(--blue-bg-color);
  color: var(--gradient-start-color);
  border-radius: 50%;
  padding: 6px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
}
.referral-numbers-names {
  font-size: 16px;
  font-weight: 600;
}

.referral-names h6 {
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
}

.referral-text h3 {
  font-size: 24px;
  color: var(--gradient-start-color);
  font-weight: 600;
}

.referral-text h5 {
  font-size: 12px;
  font-weight: 500;
}
.referral-card {
  padding: 12px;
  border-radius: 12px;
  box-shadow: rgba(120, 120, 134, 0.2) 0px 7px 29px 0px;
}

.referral-graph-button-yearly {
  color: var(--white-color);
  background-color: var(--gradient-start-color);
  font-size: 14px;
  padding: 8px 25px;
}

.referral-graph-button-monthly {
  color: var(--black-text-color);
  font-size: 14px;
}

.referral-graph-main {
  border: 1px solid var(--border-color);
  background-color: var(--white-color);
  padding: 15px;
  border-radius: 10px;
}

.application-table-section h6 {
  font-weight: 700;
}

.view-referral-user-head {
  background-image: url("../../Assets/Images/viewreferralbgImage.svg");
  background-size: cover;
  background-position: center;
  padding: 15px;
  border-radius: 10px;
}

.view-referral-user-head h6 {
  font-size: 14px;
}

.view-referral-user-h6 h6 {
  font-size: 14px;
  color: var(--white-color);
}

.view-referral-user-progress {
  background-color: var(--gradient-start-color);
  padding: 10px;
  border-radius: 10px;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; 
  flex-grow: 1;
}

.view-referral-user-rejected {
  background-color: var(--danger-color);
  padding: 10px;
  border-radius: 10px;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; 
  flex-grow: 1;
}

.view-referral-user-withdraw {
  background-color: var(--user-icons-3);
  padding: 10px;
  border-radius: 10px;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; 
  flex-grow: 1;
}

.view-referral-user-earn {
  background-color: var(--green-color);
  padding: 10px;
  border-radius: 10px;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; 
  flex-grow: 1;
}

.view-referral-user-head .col-lg-2 {
  display: flex;
  justify-content: center;
}

.view-table-referral-initials-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 5px;
  border-radius: 10%;
  background-color: var(--light-blue-color);
  font-weight: bold;
  font-size: 12px;
  margin-right: 5px;
}

.current-incentive-plan-head h4 {
  font-size: 20px;
  font-weight: bold;
}

.add-incentive-btn button{
  color: var(--gradient-start-color);
  background: none;
}
